const paramsMapping = {
  podcast: 'audio',
  video: 'video.vod',
  liveVideo: 'video.live',
};

const isValidAudioVisualType = (type: string): type is keyof typeof paramsMapping => {
  return ['podcast', 'video', 'liveVideo'].includes(type);
};

export function getAgnoPlayerBrand(type: string | null, sectionParams: Record<string, string>, brand?: string): string {
  if (!type || !isValidAudioVisualType(type)) {
    return '';
  }

  const brandFallback = sectionParams[`player.${paramsMapping[type]}.web`] ?? '';

  return brand ?? brandFallback;
}
