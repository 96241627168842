import getConfig from 'next/config';

import { getSetGlitrCookieId, getSetGlitrSessionId } from './getSetGlitrIds';

type CiamUtmParams = {
  menuBlockName: string | undefined;
  articleId: string | number | null | undefined;
  path: string;
};

export function createCiamLinkUtmParams(data: CiamUtmParams) {
  const { publicRuntimeConfig } = getConfig();
  const isLoginLink = data.path?.includes('auth/login') ?? false;

  const params: Record<string, string | undefined> = {
    'ext-internal': `menu-www-${data.menuBlockName}`,
    'ext-articleId': data.articleId ? data.articleId.toString() : undefined,
    'ext-prsid': getSetGlitrSessionId(),
    'ext-cid': getSetGlitrCookieId(),
  };

  if (isLoginLink && publicRuntimeConfig.REQUIRED_LOGIN_IDENTITY_LEVEL) {
    params['login_identity_level'] = publicRuntimeConfig.REQUIRED_LOGIN_IDENTITY_LEVEL;
  }
  if (!isLoginLink && publicRuntimeConfig.REQUIRED_REGISTER_IDENTITY_LEVEL) {
    params['register_identity_level'] = publicRuntimeConfig.REQUIRED_REGISTER_IDENTITY_LEVEL;
  }

  return params;
}
