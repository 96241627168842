import type { ReactElement, ReactNode } from 'react';

type ConditionalContainerProps = {
  condition: boolean;
  renderContainer: (children: ReactNode) => ReactElement;
  children: ReactNode;
};

function ConditionalContainer({ condition, renderContainer, children }: ConditionalContainerProps): ReactElement {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return condition ? renderContainer(children) : <>{children}</>;
}

export default ConditionalContainer;
