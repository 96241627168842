/* eslint-disable max-lines */
/* eslint-disable import/max-dependencies */
import '@mediahuis/chameleon-reset';
import '@mediahuis/chameleon-theme-wl/fonts.css';

import { AgnoplayProvider } from '@news-components/agnoplay-plugins-react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { type ComponentPropsWithRef, type ReactElement, useMemo } from 'react';

import {
  ePaperLinkHref,
  ePaperLinkHrefTarget,
  ePaperLinkImage,
  ePaperLinkLabel,
  navigationConfig,
  searchPrefix,
} from '@hubcms/brand';
import '@hubcms/brand/scss/variables.css';
import { EnvProvider } from '@hubcms/data-access-env';
import { ModalProvider } from '@hubcms/data-access-modal';
import { NavigationProvider } from '@hubcms/data-access-navigation';
import { ScenarioProvider } from '@hubcms/data-access-paywall';
import { TrackingDataProvider } from '@hubcms/data-access-tracking';
import {
  isAudioVisualArticleContext,
  isTagPage,
  isTextualArticleContext,
  type MainContentQueryResultData,
} from '@hubcms/domain-cook';
import { AccountConsent } from '@hubcms/feature-account-consent';
import { AuthProvider } from '@hubcms/feature-auth';
import { AsideMenu, Header as Header2 } from '@hubcms/feature-navigation';
import { WeatherIcon } from '@hubcms/feature-weather';
import { ConditionalContainer } from '@hubcms/ui-container';
import { EPaperLink, Footer, Header, SubscriptionButton } from '@hubcms/ui-navigation';
import { getAgnoPlayerBrand } from '@hubcms/utils-agno-player';
import { mapMySectionToNav } from '@hubcms/utils-my-section';
import {
  activateSection,
  filterSpacers,
  getNavigationVersion,
  mapNavColumn,
  mapNavItem,
  mapSocialNavItem,
  parseNavigationTree,
} from '@hubcms/utils-navigation';
import { enrichLoginUrlWithTrackingQueryParams, getSetGlitrCookieId, getSetGlitrSessionId } from '@hubcms/utils-tracking';

import '@hubcms/feature-ads/takeover-ad.css';

import { AppMeta } from '../components/PageHead/AppMeta';
import { UserMenuWrapper } from '../components/UserMenuWrapper';
import type { PageProps, PagePropsData } from '../domain/PageProps';
import { useArticleId } from '../utils';

import './global.css';
import './theme-ext.css';

const MORE_LABEL_KEY = 'navigation.more';
const SEARCH_BUTTON_LABEL_KEY = 'navigation.searchlabel';
const SEARCH_PLACEHOLDER_KEY = 'searchlabel.textbox';
const CANCEL_LABEL_KEY = 'text.cancel';

interface Data {
  resolution?: {
    section?: {
      href?: string;
    };
    remainingPath?: string;
  };
  context?: {
    __typename?: 'SectionPage' | string;
    homeSection?: {
      href?: string;
    };
  };
}

const getSectionHref = (data: Data): string => {
  const determineUrl = () => {
    if (isTagPage(data as MainContentQueryResultData))
      return `${data?.resolution?.section?.href}${data?.resolution?.remainingPath}`;
    if (data?.context?.__typename === 'SectionPage') return data?.resolution?.section?.href;
    return data?.context?.homeSection?.href;
  };

  const url = determineUrl();
  if (url?.includes('localhost')) {
    const { pathname } = new URL(url);
    return pathname;
  }
  return url || '';
};

const defaultHeaderData = {
  headerTopicsItems: [],
  headerTopicsMobileItems: [],
  headerServicesItems: [],
  asideFocusItems: [],
  asidePrimaryItems: [],
  asideSecondaryItems: [],
  asideServicesItems: [],
  subTreeItems: [],
  subTreeTitle: '',
};

function getNavigationV2Items(header: PagePropsData['header'] | undefined, slug: string, isHomePage = false) {
  if (!header) {
    return defaultHeaderData;
  }

  const headerTopicsItems = header.navigationHeaderTopicsMenu.filter(filterSpacers).map(mapMySectionToNav).map(mapNavItem);
  const headerTopicsMobileItems = isHomePage
    ? header.navigationHeaderTopicsMobileMenu.filter(filterSpacers).map(mapMySectionToNav).map(mapNavItem)
    : [];
  const headerServicesItems = header.navigationHeaderServicesMenu.filter(filterSpacers).map(mapMySectionToNav).map(mapNavItem);
  const asideFocusItems = header.navigationAsideFocusMenu.filter(filterSpacers).map(mapMySectionToNav).map(mapNavItem);
  const asidePrimaryItems = header.navigationAsidePrimaryMenu.filter(filterSpacers).map(mapMySectionToNav).map(mapNavItem);
  const asideSecondaryItems = header.navigationAsideSecondaryMenu.filter(filterSpacers).map(mapMySectionToNav).map(mapNavItem);
  const asideServicesItems = header.navigationAsideServicesMenu.filter(filterSpacers).map(mapMySectionToNav).map(mapNavItem);
  const subTreeContent = parseNavigationTree(header.navigationSubTreeMenu, `/${slug}`);
  const subTreeItems = subTreeContent.navItems.map(mapMySectionToNav).map(mapNavItem);

  return {
    headerTopicsItems,
    headerTopicsMobileItems,
    headerServicesItems,
    asideFocusItems,
    asidePrimaryItems,
    asideSecondaryItems,
    asideServicesItems,
    subTreeItems,
    subTreeTitle: subTreeContent.title,
  };
}

const ERROR_PATHS = ['/_error', '/500'];

export default function App({ Component, pageProps }: AppProps<PageProps>): ReactElement {
  const { pathname } = useRouter();
  const articleId = useArticleId();

  const isErrorPage = ERROR_PATHS.includes(pathname);

  const sectionParams = pageProps.data?.sectionParams ?? {};
  const moreLabel = sectionParams[MORE_LABEL_KEY] ?? 'More';
  const searchButtonLabel = sectionParams[SEARCH_BUTTON_LABEL_KEY] ?? 'Search';
  const searchPlaceholder = sectionParams[SEARCH_PLACEHOLDER_KEY] ?? 'Search...';
  const cancelLabel = sectionParams[CANCEL_LABEL_KEY] ?? 'Cancel';

  const navigationVersion = getNavigationVersion();

  const handleSearch = (searchTerm: string) => {
    window.location.href = `/${searchPrefix}?q=${searchTerm}`;
  };

  const trackingData = useMemo(() => {
    if (
      pageProps.data &&
      'context' in pageProps.data &&
      (isTextualArticleContext(pageProps.data.context) || isAudioVisualArticleContext(pageProps.data.context))
    ) {
      return { pageid: pageProps.data.context.id };
    }

    return {};
  }, [pageProps.data]);

  const headerData: Pick<ComponentPropsWithRef<typeof Header>, 'navigation' | 'todaysDate'> = useMemo(() => {
    const sectionActivator = activateSection(getSectionHref(pageProps.data as Data));

    return {
      navigation: {
        main: (pageProps?.data?.header?.mainNavigation || []).map(mapMySectionToNav).map(mapNavColumn).map(sectionActivator),
        more: (pageProps?.data?.header?.mainNavigationMore || []).map(mapMySectionToNav).map(mapNavColumn).map(sectionActivator),
        servicesStart: (pageProps?.data?.header?.servicesLeft || []).map(mapMySectionToNav).map(mapNavItem),
        servicesEnd: (pageProps?.data?.header?.servicesRight || []).map(mapMySectionToNav).map(mapNavItem),
      },
      todaysDate: pageProps?.data?.todaysDate || '',
      services: pageProps.weatherData ? <WeatherIcon {...pageProps.weatherData} /> : null,
    };
  }, [pageProps]);

  const userMenuItems = useMemo(() => (pageProps?.data?.header?.userMenu || []).map(mapNavItem), [pageProps]);
  const subscriptionMenuItems = useMemo(() => (pageProps?.data?.header?.subscriptionMenu || []).map(mapNavItem), [pageProps]);

  const footerData: ComponentPropsWithRef<typeof Footer> = useMemo(
    () => ({
      copyright: (pageProps?.data?.footer?.copyright || []).map(mapNavItem),
      primaryNavColumns: (pageProps?.data?.footer?.menuItemsRight || []).map(mapNavColumn),
      secondaryNavColumns: (pageProps?.data?.footer?.menuItemsLeft || []).map(mapNavColumn),
      socials: (pageProps?.data?.footer?.socials || []).map(mapSocialNavItem),
    }),
    [pageProps],
  );
  const loginUrl = useMemo(
    () =>
      enrichLoginUrlWithTrackingQueryParams('/auth/login', {
        articleId,
        sessionId: getSetGlitrSessionId(),
        cookieId: getSetGlitrCookieId(),
      }),
    [articleId],
  );

  const navigationItemsV2 =
    navigationVersion === '2'
      ? getNavigationV2Items(pageProps?.data?.header, pageProps?.slug ?? '', pathname === '/')
      : defaultHeaderData;

  const agnoplayerBrand = getAgnoPlayerBrand('video', sectionParams);
  const enableTeaserVideoPreview = pageProps?.env?.NEXT_PUBLIC_ENABLE_TEASER_VIDEO_PREVIEW === 'true';

  return (
    <EnvProvider value={pageProps.env}>
      <Head>
        {/* Overwrite Next.js's default viewport meta tag `<meta name="viewport" content="width=device-width" />`. This change provides a better fallback for older browsers that do not support CSS Containment, which is used for the scroll wrappers. Without CSS Containment support and without an initial scale of 1, the viewport will resize to match the width of the scroll wrapper's content. Although the page content will still render at its intended size, it will be rescaled relative to the viewport width, resulting in tiny and unreadable text. While adding an initial scale of 1 does not prevent horizontal scrolling of the page, it at least ensures the content remains readable. */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      {pageProps.metaData && (
        <AppMeta metaData={pageProps.metaData} titleSuffix={pageProps.data?.sectionParams['boilerplate.brandname']} />
      )}
      <AuthProvider>
        <ScenarioProvider value={pageProps.scenario ?? 'consented'}>
          <ModalProvider>
            <TrackingDataProvider value={trackingData}>
              <NavigationProvider
                advertisement={navigationConfig.advertisement}
                subnavItems={navigationItemsV2.subTreeItems}
                subnavTitle={navigationItemsV2.subTreeTitle}
              >
                {!isErrorPage && !pageProps.isBundle && navigationVersion === '1' && (
                  <Header
                    moreButtonLabel={moreLabel}
                    searchButtonLabel={searchButtonLabel}
                    searchInputPlaceholder={searchPlaceholder}
                    searchCancelLabel={cancelLabel}
                    onSearchSubmit={handleSearch}
                    inlineStartElements={
                      <EPaperLink
                        href={ePaperLinkHref}
                        image={ePaperLinkImage}
                        label={ePaperLinkLabel}
                        target={ePaperLinkHrefTarget}
                      />
                    }
                    subscriptionButton={<SubscriptionButton subscriptionMenuItems={subscriptionMenuItems} />}
                    userMenuButton={<UserMenuWrapper loginUrl={loginUrl} userMenuItems={userMenuItems} />}
                    {...headerData}
                  />
                )}
                {!isErrorPage && !pageProps.isBundle && navigationVersion === '2' && (
                  <Header2
                    loginUrl={loginUrl}
                    serviceItems={navigationItemsV2.headerServicesItems}
                    subscriptionItem={subscriptionMenuItems[0]}
                    topicItemsDesktop={navigationItemsV2.headerTopicsItems}
                    topicItemsMobile={navigationItemsV2.headerTopicsMobileItems}
                    userMenuItems={userMenuItems}
                  />
                )}

                {/* currently - the AgnoplayProvider is only used for video teaser previews. */}
                {/* only add the provider if the preview feature is enabled */}
                <ConditionalContainer
                  condition={enableTeaserVideoPreview}
                  renderContainer={children => <AgnoplayProvider brand={agnoplayerBrand}>{children}</AgnoplayProvider>}
                >
                  <Component {...pageProps} />
                </ConditionalContainer>

                {!isErrorPage && navigationVersion === '2' && (
                  <AsideMenu
                    accountSection={{
                      items: userMenuItems,
                      title: 'Account',
                    }}
                    focusItems={navigationItemsV2.asideFocusItems}
                    primaryItems={navigationItemsV2.asidePrimaryItems}
                    searchInputPlaceholder={searchPlaceholder}
                    servicesSection={{
                      items: navigationItemsV2.asideServicesItems,
                    }}
                    onSearch={handleSearch}
                    secondaryItems={navigationItemsV2.asideSecondaryItems}
                  />
                )}

                {!isErrorPage && !pageProps.isBundle && <Footer {...footerData} />}

                {!isErrorPage && !pageProps.isBundle && <AccountConsent />}
              </NavigationProvider>
            </TrackingDataProvider>
          </ModalProvider>
        </ScenarioProvider>
      </AuthProvider>
    </EnvProvider>
  );
}
