import { breakpointXsMax, breakpointSmMax, breakpointMdMax, breakpointLgMax } from '@mediahuis/chameleon-theme-wl/tokens';
import { useWindowSize } from 'usehooks-ts';

export function useIsScreenSize(screenSize: 'xs' | 'sm' | 'md' | 'lg') {
  const { width } = useWindowSize({ initializeWithValue: false });
  if (typeof width === 'number') {
    // Imported breakpoints are defined in px, e.g. "320px"
    switch (screenSize) {
      case 'xs':
        return width <= parseInt(breakpointXsMax, 10);
      case 'sm':
        return width <= parseInt(breakpointSmMax, 10);
      case 'md':
        return width <= parseInt(breakpointMdMax, 10);
      case 'lg':
        return width <= parseInt(breakpointLgMax, 10);
      default:
        return false;
    }
  }
  return false;
}
