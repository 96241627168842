import { createContext } from 'react';

import type { NavItem, NavigationAdvertisement } from '@hubcms/domain-navigation';

export type NavigationContextValue = {
  advertisement: NavigationAdvertisement | null;
  isAsideMenuOpen: boolean;
  subnavItems: NavItem[];
  subnavTitle: string;
  toggleIsAsideMenuOpen: () => void;
};

export const NavigationContext = createContext<NavigationContextValue | null>(null);
