import { IconButton } from '@mediahuis/chameleon-react';
import {
  SocialFacebook,
  SocialInstagram,
  SocialX,
  Mail,
  SocialWhatsapp,
  SocialLinkedin,
} from '@mediahuis/chameleon-theme-wl/icons';
import cx from 'classnames';
import type { CSSProperties, ComponentType, FC } from 'react';

import { buttonAppearanceMapper } from '@hubcms/brand';
import type { SocialBrand } from '@hubcms/domain-social';

import { NavigationItem } from '../NavigationItem';

import styles from './social-link.module.scss';
import type { SocialLinkProps } from './types';

const socialMediaIcons: Record<SocialBrand, ComponentType> = {
  twitter: SocialX,
  facebook: SocialFacebook,
  instagram: SocialInstagram,
  email: Mail,
  whatsapp: SocialWhatsapp,
  linkedin: SocialLinkedin,
};

const socialMediaColors: Record<SocialBrand, string | undefined> = {
  twitter: undefined,
  facebook: '#1877F2',
  instagram: undefined,
  email: undefined,
  whatsapp: undefined,
  linkedin: undefined,
};

const SocialLink: FC<SocialLinkProps> = ({
  trackingBlock,
  trackingName,
  brand,
  href,
  target,
  className,
  size = 'sm',
  isColored,
  ...props
}) => {
  const icon = socialMediaIcons[brand];
  if (!icon) {
    return null;
  }
  const color = isColored ? socialMediaColors[brand] : undefined;

  const sizes = Array.isArray(size) ? size : [size];

  return (
    <NavigationItem
      href={href}
      target={target || '_blank'}
      className={cx(styles.socialLink, className)}
      style={{ '--i-social-icon-color': color } as CSSProperties}
      trackingName={trackingName}
      trackingBlock={trackingBlock}
      {...props}
    >
      {sizes.map(iconSize => {
        return (
          <IconButton
            key={iconSize}
            accessibilityLabel={`Share this with ${brand}`}
            circular
            size={iconSize}
            icon={icon}
            appearance={buttonAppearanceMapper.outline}
            className={cx(styles.socialIconButton, sizes.length > 1 ? styles[`socialLinkIcon-${iconSize}`] : '')}
          />
        );
      })}
    </NavigationItem>
  );
};

export default SocialLink;
