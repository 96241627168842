export function enrichLoginUrlWithTrackingQueryParams(
  loginUrl: string,
  { articleId, sessionId, cookieId }: { articleId: string | null; sessionId: string; cookieId: string },
) {
  const trackingSearchparams = new URLSearchParams();
  if (typeof articleId === 'string') {
    trackingSearchparams.append('ext-articleId', articleId);
  }
  trackingSearchparams.append('ext-internal', 'menu-www-btn-header');
  trackingSearchparams.append('ext-prsid', sessionId);
  trackingSearchparams.append('ext-cid', cookieId);
  return `${loginUrl}?${trackingSearchparams}`;
}
