import type { MouseEventHandler } from 'react';

import { createTrackingClickHandler } from '@hubcms/data-access-tracking';
import type { TrackingEvent } from '@hubcms/domain-tracking';
import { isNonNull } from '@hubcms/utils-browser';
import {
  createClickCiamEvent,
  createClickSubscriptionShopEvent,
  createNavigationOrOutboundClickEvent,
  isClickData,
  mapClickCiamData,
  mapClickData,
  mapClickSubscriptionShopData,
} from '@hubcms/utils-tracking';

export function createNavigationItemClickHandler(
  onClick?: MouseEventHandler | (() => void),
  trackingBlock?: string,
  trackingName?: string,
  href?: string,
  isSiteDomain = false,
  isAboshopDomain = false,
  isCiamDomain = false,
) {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const clickTrackingData = mapClickData({
    clickitemblock: trackingBlock,
    clickitemname: trackingName,
    clickitemurl: href,
  });
  const clickSubscriptionShopData = mapClickSubscriptionShopData(clickTrackingData);
  const clickCiamData = isClickData(clickTrackingData) && mapClickCiamData(clickTrackingData, href);

  const clickEvent =
    clickTrackingData &&
    createNavigationOrOutboundClickEvent(clickTrackingData, {
      isSiteDomain,
    });
  const clickSubscriptionShopEvent =
    isSiteDomain && isAboshopDomain
      ? clickSubscriptionShopData && createClickSubscriptionShopEvent(clickSubscriptionShopData)
      : null;
  const clickCiamEvent = isSiteDomain && isCiamDomain && clickCiamData ? createClickCiamEvent(clickCiamData) : null;
  const trackingEvents: TrackingEvent[] = [clickEvent, clickSubscriptionShopEvent, clickCiamEvent].filter(isNonNull);

  if (trackingEvents.length === 0) {
    return onClick;
  }

  return createTrackingClickHandler(trackingEvents);
}
