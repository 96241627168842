import type { ClickCiamEvent } from '@hubcms/domain-tracking';

export type ClickCiamEventData = {
  type: 'login' | 'registration';
  itemdescription: string;
};

export function createClickCiamEvent(data: ClickCiamEventData): ClickCiamEvent {
  return {
    event: 'page_event',
    eventaction: 'click',
    eventcategory: 'menu',
    eventlabel: data.type,
    itemdescription: data.itemdescription,
    // eslint-disable-next-line camelcase
    ga_event_name: data.type === 'login' ? 'login_click' : 'sign_up_click',
    method: `menu-www-${data.itemdescription}`,
  };
}
