import { DropdownItem } from '@mediahuis/chameleon-react';

import { useExternalConsentUrl } from '@hubcms/utils-navigation';

import type { UserMenuItemProps } from './types';

function UserMenuItem({ item }: UserMenuItemProps) {
  const { uniqueId, text, url, target } = item;

  const externalUrl = useExternalConsentUrl(url || null);

  return <DropdownItem title={text} key={uniqueId} href={externalUrl || url} target={target} as="a" />;
}

export default UserMenuItem;
