import { useState } from 'react';

import { Container } from '@hubcms/ui-container';
import { useOnClickOutside } from '@hubcms/utils-browser';

import { SearchBar } from '../SearchBar';

import styles from './header-search-overlay.module.scss';

type HeaderSearchOverlayProps = {
  placeholder: string;
  cancelLabel: string;
  onCancel: () => void;
  onSubmit: (searchTerm: string) => void;
};

function HeaderSearchOverlay({ placeholder, cancelLabel, onSubmit, onCancel }: HeaderSearchOverlayProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const { elementRef } = useOnClickOutside<HTMLDivElement>(() => onCancel(), true);

  const onSearchBarChange = (value: string) => {
    setSearchTerm(value);
  };
  const onSearchSubmit = () => {
    setSearchTerm('');
    onSubmit(searchTerm);
  };

  return (
    <Container className={styles.searchOverlay}>
      <div ref={elementRef} className={styles.searchBarContainer}>
        <SearchBar
          className={styles.searchBar}
          value={searchTerm}
          placeholder={placeholder}
          cancelLabel={cancelLabel}
          hideSubmitButton={true}
          onChange={onSearchBarChange}
          onSubmit={onSearchSubmit}
          onCancel={onCancel}
          data-testid="search-input"
        />
      </div>
    </Container>
  );
}

export default HeaderSearchOverlay;
