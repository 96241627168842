import { sendGTMEvents } from '@hubcms/data-access-gtm';
import type { TrackingEvent } from '@hubcms/domain-tracking';
import { error } from '@hubcms/utils-monitoring';

export async function sendTrackingEvent(events: TrackingEvent | TrackingEvent[]): Promise<void> {
  try {
    await sendGTMEvents(events, { timeout: 500 });
  } catch (err) {
    error(err);
  }
}
