import {
  WeatherDaySunny,
  WeatherThunderstorm,
  WeatherSofthail,
  WeatherSnowyRain,
  WeatherSnowfall,
  WeatherRain,
  WeatherNightFreezingRain,
  WeatherNightDrizzle,
  WeatherLightSnowfall,
  WeatherHeavyRain,
  WeatherHeavyOvercast,
  WeatherFog,
  WeatherDayFreezingRain,
  WeatherNightClear,
  WeatherDaySlightlyCloudy,
  WeatherNightSlightlyCloudy,
  WeatherDayDrizzle,
} from '@mediahuis/chameleon-theme-wl/icons';
import type { ReactElement } from 'react';

import { weatherSectionPage } from '@hubcms/brand';

const getIcon = (code: number): ReactElement | null => {
  switch (code) {
    case 2:
      return WeatherDaySunny;
    case 4:
    case 5:
    case 7:
      return WeatherDaySlightlyCloudy;
    case 6:
    case 36:
      return WeatherHeavyOvercast;
    case 8:
      return WeatherDayDrizzle;
    case 9:
    case 39:
      return WeatherRain;
    case 10:
    case 18:
    case 40:
    case 48:
      return WeatherSnowyRain;
    case 11:
    case 14:
    case 41:
    case 44:
      return WeatherSnowfall;
    case 12:
    case 42:
      return WeatherLightSnowfall;
    case 13:
    case 43:
      return WeatherHeavyRain;
    case 19:
    case 49:
      return WeatherSofthail;
    case 20:
    case 50:
      return WeatherThunderstorm;
    case 21:
      return WeatherDayFreezingRain;
    case 23:
    case 53:
      return WeatherFog;
    case 32:
      return WeatherNightClear;
    case 34:
    case 35:
    case 37:
      return WeatherNightSlightlyCloudy;
    case 38:
      return WeatherNightDrizzle;
    case 51:
      return WeatherNightFreezingRain;
    default:
      return null;
  }
};

type UseWeatherIconType = {
  icon: ReactElement | null;
  weatherSectionPage?: string;
};

export function useWeatherIcon({ wrCode }: { wrCode: number }): UseWeatherIconType {
  const icon = getIcon(wrCode);

  return {
    icon,
    weatherSectionPage,
  };
}
