import type { TrackingData } from '@hubcms/domain-tracking';

import type { ClickCiamEventData } from './create-click-ciam-event';

export function mapClickCiamData(data: TrackingData, href?: string): ClickCiamEventData {
  const isLoginLink = href?.includes('auth/login') ?? false;

  return {
    type: isLoginLink ? 'login' : 'registration',
    itemdescription: data.clickitemblock?.toLowerCase() ?? '',
  };
}
