import type { PropsWithChildren } from 'react';
import { useEffect, useId, useMemo, useState } from 'react';

import type { NavItem, NavigationAdvertisement } from '@hubcms/domain-navigation';
import { useNoBodyScroll } from '@hubcms/utils-browser';

import { NavigationContext } from './context';

type NavigationProviderProps = PropsWithChildren<{
  advertisement?: NavigationAdvertisement;
  subnavItems?: NavItem[];
  subnavTitle: string;
}>;

function NavigationProvider({ advertisement, children, subnavItems = [], subnavTitle }: NavigationProviderProps) {
  const [isAsideMenuOpen, setIsAsideMenuOpen] = useState(false);
  const toggleIsAsideMenuOpen = () => setIsAsideMenuOpen(prevValue => !prevValue);
  const transformedAdvertisement = useMemo(() => (advertisement ? transformAdvertisement(advertisement) : null), [advertisement]);

  const componentId = useId();
  const { addNoScrollToBody, removeNoScrollFromBody } = useNoBodyScroll(componentId, true);

  useEffect(() => {
    // We need to manually set the overflow so the AsideMenu will not scroll the body in the background
    if (isAsideMenuOpen) {
      addNoScrollToBody();
    } else {
      removeNoScrollFromBody();
    }
  }, [isAsideMenuOpen, addNoScrollToBody, removeNoScrollFromBody]);

  return (
    <NavigationContext.Provider
      value={{
        advertisement: transformedAdvertisement,
        isAsideMenuOpen,
        subnavItems,
        subnavTitle,
        toggleIsAsideMenuOpen,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
}

function transformAdvertisement(advertisement: NavigationAdvertisement): NavigationAdvertisement {
  return {
    ...advertisement,
    slot: `${advertisement.format}-${advertisement.slot}`,
  };
}

export default NavigationProvider;
