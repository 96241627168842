import type { MouseEvent as ReactMouseEvent } from 'react';

import { isClickEvent } from './is-click-event';
import { isHTMLAnchorEventTarget } from './is-html-anchor-event-target';
import { isNonEmptyHref } from './is-non-empty-href';
import { isPrimaryMouseEvent } from './is-primary-mouse-event';
import { isTargetSelf } from './is-target-self';

type AsyncMouseEventAction<T> = (event: T) => Promise<void> | void;

type AsyncMouseEventHandler<T, K = AsyncMouseEventAction<T> | void> = (event: T) => Promise<K> | K;

export function createAsyncMouseEventHandler<T extends MouseEvent | ReactMouseEvent>(
  handler: AsyncMouseEventHandler<T>,
): AsyncMouseEventHandler<T, void> {
  return async function handleAsyncMouseEvent(event) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let defaultAction: AsyncMouseEventAction<T> = () => {};

    if (
      isPrimaryMouseEvent(event) &&
      isClickEvent(event) &&
      isHTMLAnchorEventTarget(event.currentTarget) &&
      isTargetSelf(event.currentTarget) &&
      isNonEmptyHref(event.currentTarget)
    ) {
      event.preventDefault();
      const defaultURL = event.currentTarget.href;
      defaultAction = () => window.location.assign(defaultURL);
    }

    const customAction = await handler(event);
    const action = customAction ?? defaultAction;
    await action(event);
  };
}
