const NoScrollRequesterIds: Set<string> = new Set();

export const useNoBodyScroll = (componentId: string, mobileOnly: boolean = false) => {
  const scrollClass = mobileOnly ? 'noscrollMobile' : 'noscroll';

  function addNoScrollToBody() {
    if (!NoScrollRequesterIds.size && typeof document !== 'undefined') {
      document.body.classList.add(scrollClass);
    }
    NoScrollRequesterIds.add(componentId);
  }

  function removeNoScrollFromBody() {
    NoScrollRequesterIds.delete(componentId);
    if (!NoScrollRequesterIds.size && typeof document !== 'undefined') {
      document.body.classList.remove(scrollClass);
    }
  }

  return { addNoScrollToBody, removeNoScrollFromBody };
};
