import type { MenuItemFragment } from '@hubcms/domain-cook';
import type { SocialNavItem } from '@hubcms/domain-navigation';

import { normalizeMenuItem } from './normalizeMenuItem';

export function mapSocialNavItem(menuItemFragment: MenuItemFragment, idx: number): SocialNavItem {
  const normalizedMenuItem = normalizeMenuItem(menuItemFragment, idx);
  return {
    ...normalizedMenuItem,
    brand: normalizedMenuItem.text?.toLowerCase() as SocialNavItem['brand'],
  };
}
