import cx from 'classnames';
import { type ComponentPropsWithoutRef, useId, useState } from 'react';

import { headerDateFormat } from '@hubcms/brand';
import type { NavColumn } from '@hubcms/domain-navigation';
import { DateTime } from '@hubcms/ui-temporal';
import { ChangeCase } from '@hubcms/ui-textual';
import { useNoBodyScroll } from '@hubcms/utils-browser';

import HeaderLogo from './HeaderLogo';
import { HeaderNavigation } from './HeaderNavigation';
import HeaderSearchOverlay from './HeaderSearchOverlay';
import HeaderServices from './HeaderServices';
import styles from './header.module.scss';
import type { HeaderProps } from './types';
import { useOpenMenus } from './useOpenMenus';

function SubNavigation(props: ComponentPropsWithoutRef<typeof HeaderNavigation.Main.SubNavigation>) {
  return <HeaderNavigation.Main.SubNavigation {...props} />;
}

function useSubMenu(mainColumns: NavColumn[], isMoreMenuOpen: boolean) {
  const activeMainItem = mainColumns.find(({ heading: { isActive } }) => isActive);
  const isMainSubItemsVisible = Boolean(activeMainItem?.subItems.length) && !isMoreMenuOpen;
  const activeMainSubItems = activeMainItem?.subItems ?? [];

  return {
    isMainSubItemsVisible,
    activeMainSubItems,
  };
}

const MAIN_MORE_MENU_NAME = 'main-more';
const SEARCH_MENU_NAME = 'search';

function Header({
  navigation,
  services,
  inlineStartElements,
  subscriptionButton,
  userMenuButton,
  todaysDate,
  moreButtonLabel,
  searchButtonLabel,
  searchCancelLabel,
  searchInputPlaceholder,
  onSearchSubmit,
  className,
}: HeaderProps) {
  const { closeMenu, toggleMenu, isMenuOpen } = useOpenMenus();
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);
  const componentId = useId();
  const { addNoScrollToBody, removeNoScrollFromBody } = useNoBodyScroll(componentId);

  const handleSearchClicked = () => {
    toggleMenu(SEARCH_MENU_NAME);
  };

  const handleSearchSubmit = (searchTerm: string) => {
    closeMenu();
    onSearchSubmit(searchTerm);
  };

  const handleSearchCancel = () => {
    closeMenu();
  };

  const handleMoreClicked = () => {
    toggleMenu(MAIN_MORE_MENU_NAME);
  };

  const handleMenuClicked = () => {
    setIsMobileNavigationOpen(isOpen => {
      const newIsOpen = !isOpen;
      if (newIsOpen) {
        addNoScrollToBody();
      } else {
        removeNoScrollFromBody();
      }
      return newIsOpen;
    });
  };

  const isSearchBarVisible = isMenuOpen(SEARCH_MENU_NAME);
  const isMoreMenuOpen = isMenuOpen(MAIN_MORE_MENU_NAME);
  const hasMoreMenu = Boolean(navigation.more.length);
  const isMoreMenuVisible = hasMoreMenu && isMoreMenuOpen;

  const { activeMainSubItems, isMainSubItemsVisible } = useSubMenu(navigation.main, isMoreMenuOpen);

  return (
    <header className={cx(styles.header, className, 'no-print')}>
      <HeaderServices startItems={navigation.servicesStart} endItems={navigation.servicesEnd}>
        {todaysDate && headerDateFormat && (
          <ChangeCase casing="uppercase-first" as={DateTime} date={todaysDate} format={headerDateFormat} />
        )}
        {services}
      </HeaderServices>
      <HeaderLogo
        onSearchClicked={handleSearchClicked}
        onMenuClicked={handleMenuClicked}
        isMainMenuOpen={isMobileNavigationOpen}
        inlineStartElements={inlineStartElements}
        subscriptionButton={subscriptionButton}
        userMenuButton={userMenuButton}
      />
      <HeaderNavigation isMobileNavigationOpen={isMobileNavigationOpen} data-testid="header-main">
        <HeaderNavigation.Main
          columns={navigation.main}
          onMoreClicked={handleMoreClicked}
          isMoreMenuOpen={isMoreMenuOpen}
          moreButtonLabel={moreButtonLabel}
          searchButtonLabel={searchButtonLabel}
          onSearchClicked={handleSearchClicked}
          hasMoreButton={hasMoreMenu}
        />
        {hasMoreMenu && <HeaderNavigation.MoreMenu isMoreMenuVisible={isMoreMenuVisible} columns={navigation.more} />}
      </HeaderNavigation>
      {isSearchBarVisible && (
        <HeaderSearchOverlay
          placeholder={searchInputPlaceholder}
          cancelLabel={searchCancelLabel}
          onSubmit={handleSearchSubmit}
          onCancel={handleSearchCancel}
        />
      )}
      {isMainSubItemsVisible && (
        <nav className={styles.extraNavigation} aria-label="sub-main">
          <SubNavigation navItems={activeMainSubItems} />
        </nav>
      )}
    </header>
  );
}

export default Header;
